<script setup>
import { 
  ref,
  defineProps,
  onMounted,
  inject,
  onBeforeUnmount,
  onDeactivated,
  toRefs,
} from "vue";
import XSelect from "@/components/basic/XSelect.vue";
import PieWithLineChart from "@/components/dashboard/charts/PieWithLineChart.vue";
import CurrentTimeBlock from "@/components/dashboard/other/CurrentTimeBlock.vue";
import UiTable from "@/components/basic/tables/UiTable.vue";
import UiIconLink from "@/components/basic/links/UiIconLink.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";

import { useServicesTabData } from "@/composition/dashboard/use-services-tab-data";
import { useServicesMiniTable, TEST_RESULTS_COLUMNS_MINI } from "@/composition/dashboard/use-test-results-mini-table";
import { useDashboardFilters } from "@/composition/dashboard/use-dashboard-filters";
import { DEFAULT_PARAMS } from "@/composition/tables/use-table-api";
import { TEST_STEP_STATUS_LEGEND_TITLES, TEST_STEP_STATUS_MAP } from "@/composition/dashboard/constants";
import TEST_STATUS_CONFIG from "@/cfg/testStatus";
import { shaKey } from "@/js/helper";
import { unixToDateTimeString } from '@/js/general';

const props = defineProps({
  range: {
    type: Object,
    required: true,
  },
  mccs: {
    type: Array,
    default: () => ([])
  },
});

const { mccs, range } = toRefs(props);

const {
  openDialog
} = inject("DialogsRoot");

const {
  addToRefreshQueue,
  removeFromRefreshQueue,
} = inject("DashboardView2");

const isLoading = ref(true);

const {
  currentMCC,
  mccReqParam,
  mccSelectOptions,
  currentTestStatus,
  testStatusReqParam,
  testStatusSelectOptions,
  rangeInSecForReq,
} = useDashboardFilters({ mccListRef: mccs, rangeRef: range });

const {
  welcomeSMSPieLineData,
  dataUsagePieLineData,
  voiceOnNetPieLineData,
  voiceInterconnectPieLineData,
  voiceInternationalPieLineData,
  runAccumulativeFetch
} = useServicesTabData();

const {
  updateTableParams,
  tableParams,
  servicesTable,
  isServicesTableLoading,
  meta,
} = useServicesMiniTable({ 
  params: {
    ...DEFAULT_PARAMS,
    mcc: mccReqParam.value,
    result: testStatusReqParam.value,
  }
});

const fetchChartsData = async (_range) => {
  await runAccumulativeFetch({ range: _range, mcc: mccReqParam.value });
};
const updateDashboard = async (newTableParams) => {
  isLoading.value = true;
  try {
    await Promise.all([
      updateTableParams(newTableParams),
      fetchChartsData(rangeInSecForReq.value),
    ]);
  } finally {
    isLoading.value = false;
  }
};

const updateMCC = async (v) => {
  currentMCC.value = v;
  await updateDashboard({ ...tableParams.value, mcc: mccReqParam.value })
};

const updateTestStatus = async (v) => {
  currentTestStatus.value = v;
  await updateDashboard({ ...tableParams.value, result: testStatusReqParam.value })
};


addToRefreshQueue("services-tab-data", async ({ isRangeChange }) => {
  if (isRangeChange) {
    isLoading.value = true;
  }

  try {
    await Promise.all([
      updateTableParams({ ...tableParams.value, from: rangeInSecForReq.value.from, to: rangeInSecForReq.value.to }, { isSilent: !isRangeChange }),
      fetchChartsData(rangeInSecForReq.value)
    ]);
  } finally {
    isLoading.value = false;
  }
});

const openExplorerDialog = async ({ id }) => {
  if (!id) {
    return;
  }
  await openDialog("ExplorerDialog", { value: true, explorerId: id });
};

const getTestInfoPath = (id) => {
  const idKey = shaKey(id.toString());
  return { name: "test-info", params: { id, idKey  } };
};
const openInNewTab = ({ href }) => {
  window.open(href, "_blank");
};

onMounted(async () => {
  await updateDashboard({ ...tableParams.value, to: rangeInSecForReq.value.to, from: rangeInSecForReq.value.from })
});


onDeactivated(() => {
  removeFromRefreshQueue("services-tab-data");
});
onBeforeUnmount(() => {
  removeFromRefreshQueue("services-tab-data");
});
</script>

<template>
  <section class="services-tab">
    <div class="services-tab__no-scroll-container">
      <div class="services-tab__top-box">
        <h2 class="services-tab__tab-title">
          Services and Products
        </h2>

        <div class="services-tab__tab-controls-box">
          <XSelect
            class="services-tab__filter-select"
            :value="currentMCC"
            label="MCC"
            item-value="mcc"
            item-text="countryCode"
            autocomplete
            :items="mccSelectOptions"
            @input="(v) => updateMCC(v)"
          />

          <XSelect
            class="services-tab__filter-select"
            :value="currentTestStatus"
            label="Status"
            autocomplete
            :items="testStatusSelectOptions"
            item-value="key"
            item-text="value"
            @input="(v) => updateTestStatus(v)"
          />
        </div>
      </div>

      <div class="services-tab__scrollable-grid">
        <div class="services-tab__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="welcomeSMSPieLineData.line"
            :pieData="welcomeSMSPieLineData.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Welcome SMS' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="services-tab__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="dataUsagePieLineData.line"
            :pieData="dataUsagePieLineData.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Data Usage' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="services-tab__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="voiceOnNetPieLineData.line"
            :pieData="voiceOnNetPieLineData.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Voice OnNet' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="services-tab__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="voiceInterconnectPieLineData.line"
            :pieData="voiceInterconnectPieLineData.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Voice Interconnect' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="services-tab__grid-item">
          <PieWithLineChart
            v-if="!isLoading"
            :lineData="voiceInternationalPieLineData.line"
            :pieData="voiceInternationalPieLineData.pie"
            :categories-map="TEST_STEP_STATUS_MAP"
            :echart-options="{
              title: { text: 'Voice International' },
              legend: { data: TEST_STEP_STATUS_LEGEND_TITLES }
            }"
          />
        </div>

        <div class="services-tab__grid-item">
          <CurrentTimeBlock lang="en" :clock-size="150" />
        </div>

        <div 
          class="services-tab__grid-item  services-tab__grid-item--take-row  services-tab__grid-item--just-cont-between"
        >
          <div class="services-tab__table-scroll-box">
            <UiTable
              class="services-tab__lazy-table"
              :columns="TEST_RESULTS_COLUMNS_MINI"
              :items="servicesTable"
              is-with-skeleton
              :is-loading="isServicesTableLoading"
              :sortBy="tableParams.sortBy"
              :sortDesc="tableParams.descending"
              :skeleton-rows="tableParams.itemsPerPage"
              @update:sorting="({ sortBy, sortDesc }) => updateTableParams({ ...tableParams, sortBy, descending: sortDesc })"
            >
              <template #cell(testCase-name)="{ cellValue, item }">
                <UiIconLink
                  :to="getTestInfoPath(item.id)"
                  custom
                  :with-icon="false"
                  @navigate="({ href }) => openInNewTab({ href })"
                >
                  {{ cellValue }}
                </UiIconLink>
              </template>

              <template #cell(subscriber)="{ cellValue }">
                <button 
                  class="services-tab__subscriber-table-cell"
                  @click="() => openExplorerDialog({ id: cellValue.id })"
                >
                  <span class="services-tab__subscriber-name">
                    {{ cellValue.name }}
                  </span>

                  {{ cellValue.alias ? `(${cellValue.alias})` : '' }}
                </button>
              </template>

              <template #cell(status)="{ cellValue }">
                <div class="services-tab__group-test-status">
                  <v-icon 
                    :color="TEST_STATUS_CONFIG[cellValue].color"
                  >
                    {{ TEST_STATUS_CONFIG[cellValue].icon }}
                  </v-icon>

                  {{ TEST_STATUS_CONFIG[cellValue].text }}
                </div>
              </template>

              <template #cell(startTime)="{ cellValue }">
                {{ unixToDateTimeString(cellValue) }}
              </template>

              <template #cell(endTime)="{ cellValue }">
                {{ unixToDateTimeString(cellValue) }}
              </template>
            </UiTable>
          </div>

          <div class="services-tab__table-controls">
            <PaginationBlock
              :total-count="meta.totalCount"
              :current-page="meta.page"
              :items-per-page="meta.itemsPerPage"
              @update:current-page="(pageN) => updateTableParams({ ...tableParams, page: pageN })"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.services-tab {
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__no-scroll-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__top-box {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-top: 8px;
  }

  &__tab-title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: .0125rem;
    line-height: 2rem;
  }

  &__tab-controls-box {
    // flex: 0 0 auto;
    display: flex;
    gap: 8px;
    padding-top: 8px;
  }

  &__filter-select {
    flex: 0 0 auto;
  }

  &__scrollable-grid {
    flex: 1 1 auto;
    min-height: 0;
    display: grid;
    grid-template-columns: repeat(10, 1fr) 125px 125px;
    grid-template-rows: minmax(300px, auto) 100%;
    grid-auto-rows: minmax(min-content, auto);
    gap: 8px;
    height: 100%;
    padding: 8px;
    background-color: rgba(0,0,0,0.05);
    
    overflow-y: auto;
    
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 8px;
    }
  }

  &__grid-item {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    background-color: white;

    @include dark-inline-skeleton($percent: 100);

    &--take-row {
      grid-column: 1 / -1;
    }

    &--just-cont-between {
      justify-content: space-between;
    }
  }

  &__table-scroll-box {
    overflow-y: auto;
    min-height: 0;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__table-controls {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }

  &__subscriber-name {
    color: var(--v-anchor-base);
  }
}
</style>

