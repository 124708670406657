<template>
  <div>
    <div class="mb-2 pa-3" v-if="Object.keys(newItems).length!==0">
      <div :style="{ 'max-width' : tableWidth+'px' }">
        <v-row class="primary white--text">
          <template v-for="(value,index) in newItems">
            <v-col
                :key="index"
                class="pa-1 table-index-column text-center"
                style=""
            > {{ languagePack("basicModemTable", index) }}
            </v-col>
          </template>
        </v-row>
        <v-row style="border: solid 1px #dbdbdb">
          <template v-for="(value,index) in newItems">
            <template v-if="typeof value==='object' && Object.keys(value).length!==0">
              <v-col :key="index">
                <template v-for="(innerValue,innerIndex) in value">
                  <template v-if="innerValue!=''">
                    <v-row
                        :key="innerIndex"
                        class="pa-1"
                    >
                      <v-col
                          class="pa-1 "
                          cols="6"
                          :class="textAlignClass"
                      >
                        {{ customIndexName(innerIndex) }}
                      </v-col>
                      <v-col
                          class="pa-1 "
                          cols="6"
                          :class="textAlignClass"
                      >
                        {{ modemUnitConversion(innerValue, innerIndex) }}
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </v-col>
            </template>
            <template v-else>
              <v-col
                  :key="index"
                  class="pa-1 table-value-column text-center"
                  :class="customClass"
                  style="min-width: 30px"
              > {{  modemUnitConversion(value, index)  }}
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
     </div>
  </div>
</template>
<script>
import {objectCustomSort,  convertSeconds, convertByte2MB,convertMBits} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
      dafaultWidth:200,
      tableWidth: "700",
      textAlignClass: "",
      nano2SecondsAttr: ['duration_dialing', 'duration_ringing_in', 'call_duration', 'duration','durationUntilLimit','smscResponseTime'],
      bytes2MbAttr: ['rx_bytes', 'tx_bytes','size','volumeUsed'],
      bits2Mbits:['bandwidth','maxSpeed','minSpeed','avgSpeed','avgSpeedLimited','maxSpeedLimited','minSpeedLimited']
    };
  },
  computed: {
    newItems() {
      this.customTextAlign(this.type);
      // console.log(this.item);
      let sortOrder = [];
      let itemList = {};
      if (this.type == 'Explorer_SMS') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode || "";
          itemList['errorMessage'] = this.item?.result?.message || "None";
        } else {
          if (this.item?.delivery_state) {
            itemList['delivery_state'] = this.item.delivery_state;
          }
          if (this.item?.smscResponseTime) {
            itemList['smscResponseTime'] = this.item.smscResponseTime;
          }
          if (this.item?.smsReferenceNumber) {
            itemList['smsReferenceNumber'] = this.item.smsReferenceNumber;
          }
        }
        if (this.item?.result?.message) {
          delete this.item.result.message;
        }
      }
      else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['duration_dialing', 'duration_ringing_in', 'call_duration'];
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          if (Object.keys(this.item?.stats).length > 0) {
            itemList['stats'] = this.item?.stats ? objectCustomSort(sortOrder, this.item.stats) : "";
          }
        }
      }
      else if (this.type == 'Explorer_cellular_start_voice_mo') {
        sortOrder = ['duration_dialing', 'duration_ringing_in'];
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          if (Object.keys(this.item?.stats).length > 0) {
            itemList['stats'] = this.item?.stats ? objectCustomSort(sortOrder, this.item.stats) : "";
          }
        }
      }
      else if (this.type == 'Explorer_USSD') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        }
      }
      else if (this.type == 'Explorer_cellular_receive_call') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else if (this.item?.state) {
          itemList['state'] = this.item?.state;
          itemList['callingNumber'] = this.item?.callingNumber;
          itemList['timeFirstRing'] = (this.item?.timeFirstRing !== '0001-01-01T00:00:00Z' && this.item?.timeFirstRing !== '0') ? this.item.timeFirstRing : "never";
          itemList['duration'] = this.item?.duration ? this.item.duration : "";
        }
      }
      else if (this.type == 'Explorer_cellular_hangup_call') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          itemList['connectedCalls'] = this.item?.connectedCalls ? this.item.connectedCalls : "";
        }
      }
      else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['state', 'stateNo'];
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else if (this.item?.register_info) {
          itemList['registerInfoState'] = this.item?.register_info?.state ? this.item.register_info.state : "";
          itemList['registerInfoStateNo'] = this.item?.register_info?.stateNo ? this.item.register_info.stateNo : "";
          itemList['operator'] = this.item?.register_info?.operator ? this.item.register_info.operator : "Unknown";
        }
      }
      else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['ipFamily', 'method', 'address', 'prefix', 'gateway', 'dns1', 'dns2', 'dns3'];
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          if (this.item?.ipv4 && Object.keys(this.item.ipv4).length > 0) {
            itemList['ipv4'] = objectCustomSort(sortOrder, this.item.ipv4);
          }
          if (this.item?.ipv6 && Object.keys(this.item.ipv6).length > 0) {
            itemList['ipv6'] = objectCustomSort(sortOrder, this.item.ipv6);
          }
        }
      }
      else if (this.type == 'Explorer_cellular_check_received_sms') {
       // sortOrder = ['subscriber', 'origin', 'from', 'text','timestamp'];
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ?? "";
        } else {
          if (this.item) {
            itemList = {
              subscriber: this.item?.subscriber ?? "",
              origin: this.item?.origin ?? "",
              from: this.item?.data?.from ?? "",
              text: this.item?.data?.text ?? "",
              timestamp: this.item?.data?.timestamp ?? ""
            };
            // itemList = objectCustomSort(sortOrder, itemList);
          }
        }
      }
      else if (this.type == 'Explorer_cellular_data_disconnect') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          let statistics = {};
          if (this.item?.statistics) {
            if (this.item?.statistics?.duration) {
              statistics = { 'duration': this.item.statistics.duration };
            }
            if (this.item?.statistics?.rx_bytes) {
              statistics = { 'rx_bytes': this.item.statistics.rx_bytes, ...statistics };
            }
            if (this.item?.statistics?.tx_bytes) {
              statistics = { 'tx_bytes': this.item.statistics.tx_bytes, ...statistics };
            }
          }
          itemList['statistics'] = statistics;
        }
      }
      else if (this.type == 'Explorer_data_usage') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode || "";
        } else {
          let statistics = {};
          if (this.item?.statistics) {
            if (this.item.statistics?.duration) {
              statistics = { 'duration': this.item.statistics.duration, ...statistics };
            }
            if (this.item.statistics?.durationUntilLimit) {
              let sDUL = this.item.statistics.durationUntilLimit !== '0' ? this.item.statistics.durationUntilLimit : "not throttled";
              statistics = { 'durationUntilLimit': sDUL, ...statistics };
            }
            if (this.item.statistics?.limitTimestamp) {
              let vrtValue = this.item.statistics.limitTimestamp === '0001-01-01T00:00:00Z' || this.item.statistics.limitTimestamp === '0'
                  ? "not throttled"
                  : this.item.statistics.limitTimestamp.split('.')[0];
              statistics = { 'limitTimestamp': vrtValue, ...statistics };
            }
            if (this.item.statistics?.volumeUsedUntilLimit) {
              statistics = { 'volumeUsedUntilLimit': this.item.statistics.volumeUsedUntilLimit, ...statistics };
            }
            if (this.item.statistics?.volumeReachedTime) {
              let vrtValue = this.item.statistics.volumeReachedTime === '0001-01-01T00:00:00Z' || this.item.statistics.volumeReachedTime === '0'
                  ? "never"
                  : this.item.statistics.volumeReachedTime.split('.')[0];
              statistics = { 'volumeReachedTime': vrtValue, ...statistics };
            }
            if (this.item.statistics?.volumeUsed) {
              statistics = { 'volumeUsed': this.item.statistics.volumeUsed, ...statistics };
            }
            if (this.item.statistics?.avgSpeed) {
              statistics = { 'avgSpeed': this.item.statistics.avgSpeed, ...statistics };
            }
            if (this.item.statistics?.maxSpeed) {
              statistics = { 'maxSpeed': this.item.statistics.maxSpeed, ...statistics };
            }
            if (this.item.statistics?.minSpeed) {
              statistics = { 'minSpeed': this.item.statistics.minSpeed, ...statistics };
            }
            if (this.item.statistics?.minSpeedLimited) {
              statistics = { 'minSpeedLimited': this.item.statistics.minSpeedLimited, ...statistics };
            }
            if (this.item.statistics?.maxSpeedLimited) {
              statistics = { 'maxSpeedLimited': this.item.statistics.maxSpeedLimited, ...statistics };
            }
            if (this.item.statistics?.avgSpeedLimited) {
              statistics = { 'avgSpeedLimited': this.item.statistics.avgSpeedLimited, ...statistics };
            }
            if (this.item.statistics?.lastState) {
              statistics = { 'lastState': this.item.statistics.lastState, ...statistics };
            }
          }
          sortOrder = ['minSpeed', 'maxSpeed', 'avgSpeed', 'volumeUsed', 'volumeReachedTime', 'minSpeedLimited', 'maxSpeedLimited', 'avgSpeedLimited', 'lastState'];
          itemList['statistics'] = statistics;
        }
      }
      else if (this.type == 'Explorer_stop_audio_recording') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          itemList['transcription'] = this.item?.transcription ? this.item.transcription : "";
        }
      }
      else if(this.type == 'Explorer_at_command') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          itemList['text'] = this.item?.text ? this.item.text : "";
        }
      }
      else if (this.type == 'Explorer_insert_sim') {
        if (this.item?.result?.successful === false) {
          itemList['errorCode'] = this.item?.result?.errorCode ? this.item.result.errorCode : "";
        } else {
          // will come once we get data from test!
        }
      }
      else {
        itemList = this.item;
      }
      this.customWidth(this.type,itemList);
      return itemList;
    },
    customClass(){
      let customClass="";
      if(this.type=='Explorer_at_command'){
        customClass = customClass+' preserveBreaks ml-3';
      }
      return customClass;
    }
  },

  methods: {
    customIndexName(index){
      let result = this.languagePack("basicModemTable", index);
    /*  if(this.type == "Explorer_data_usage"  && index=='duration'){//override over json file
        result = "Duration Until Limit";
      }*/
      return result;
    },
    modemUnitConversion(value, index) {
      if (this.nano2SecondsAttr.includes(index)) {
        value = convertSeconds(value);
      } else if (this.bytes2MbAttr.includes(index)) {
        value = convertByte2MB(value)+" ("+value+")";
      }else if(this.bits2Mbits.includes(index)){
        value = convertMBits(value);
      } else if(index=='volumeUsedUntilLimit'){
        if(value!=0){
          value = convertByte2MB(value)+" ("+value+")";
        } else {
          value = 'not throttled';
        }
      }
     // console.log(value);
      return value;
    },
    customTextAlign(type) {
      if (type == 'Explorer_cellular_data_disconnect') {
        this.textAlignClass = "text-center";
      }
    },
    customWidth(type,itemList) {
      if (type == 'Explorer_cellular_attach') {
        this.tableWidth = "730";
      } else if (type == 'Explorer_cellular_data_connect') {
        this.tableWidth = "640";
      } else if (type == 'Explorer_SMS') {
        this.tableWidth = this.dafaultWidth * Object.keys(itemList).length; // we should follow in future! each attr get 250
      } else if (type == 'Explorer_cellular_data_disconnect' || type == 'Explorer_data_usage'   ) {
        this.tableWidth = "450";
      } else if (type == 'Explorer_cellular_voice_call'  ||   type=='Explorer_at_command' ) {
        this.tableWidth = "350";
      } else if ( type=='Explorer_cellular_start_voice_mo' || type=='Explorer_cellular_hangup_call' ) {
        this.tableWidth = "250";
      }
      /*else if(type=='Explorer_cellular_check_received_sms' ){
        this.tableWidth="850";
      }*/
    },
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}
.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}
.preserveBreaks{
  white-space: pre-line;
  text-align: left !important;
}
</style>